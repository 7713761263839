import zappyride from "../../assets/images/jdp_zappyride.png"
import Grid from '@mui/material/Grid';
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';

const Footer = () => {
    return (
        <Grid alignItems="center" justifyContent="center"
            container>
            <Grid item>
                <Typography style={{
                    fontSize: "0.75rem",
                    color:"#666666",
                    position: "relative",
                    top: "2px"
                }}>powered by</Typography>
            </Grid>
            <Grid item>
                <Box
                    component="img"
                    sx={{
                        width: 158,
                        paddingLeft: "8px",
                    }}
                    alt="Zappyride"
                    src={zappyride}
                />
            </Grid>
        </Grid>
    )
}

export default Footer
