import Select from "../Select"
import { getTrims, getActiveTrim, getDealerId, getTooltips } from "../../../redux/selectors"
import { useDispatch, useSelector } from 'react-redux';
import { updateActiveTrim } from "../../../redux/Slices/userSlice";
import GaTracker from "../../../utils/GaTracker";
import { FormatAsDollars } from "../../../utils/Format";

const SelectTrim = () => {
    const dispatch = useDispatch()
    const trims = useSelector(getTrims)
    const activeTrim = useSelector(getActiveTrim)
    const dealerId = useSelector(getDealerId)
    const tooltip = useSelector(getTooltips)
    
    const options = trims.map(trim => {
        return {
            value: trim.name,
            label: trim.displayName ? trim.displayName : `${trim.name} ${FormatAsDollars(trim.msrp)}`
        }
    })
    const changeTrim = (trimName) => {
        const setTrim = trims.find(trim => trim.name === trimName)

        GaTracker.trackEvent({
            category: "Select Trim",
            action: "Selected a Trim",
            label: `Clicked ${trimName} - ${dealerId}`
        });

        dispatch(updateActiveTrim(setTrim))
    }

    return (
      <Select
        title="Trim and starting MSRP"
        options={options}
        value={activeTrim.name}
        onChange={changeTrim}
        tooltip={tooltip?.trimTip}
        tooltipPlacement="bottom"
      />
    );
}

export default SelectTrim
