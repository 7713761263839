import { useEffect, useState } from "react";
import TabTitle from "../components/TabTitle"
import SelectTrim from "../components/Inputs/Select/SelectTrim"
import SelectVehicle from "../components/Inputs/Select/SelectVehicle";
import SelectPurchaseMethod from "../components/Inputs/Select/SelectPurchaseMethod";
import IncentiveCard from "../components/IncentiveCard/IncentiveCard"
import { highestEligibleAmountIncentiveGivenType, incentiveSavingsOfGivenTypes } from "../functions/incentive/incentiveHelperFunctions"
import Grid from '@mui/material/Grid';
import TextFieldZipcode from "../components/Inputs/TextField/TextFieldZipcode"
import TabStructure from "../components/TabStructure";
import { FormatAsDollars } from "../utils/Format";
import { useSelector } from 'react-redux';
import { getTooltips, getActivePurchaseMethod} from "../redux/selectors";

const Incentives = ( {vehicle, vehicleIncentives, state} ) => {
       const [hyundaiIncentiveAmount, setHyundaiIncentiveAmount] = useState(0);
       const activePurchaseMethod = useSelector(getActivePurchaseMethod);    
    const highestStateIncentive = highestEligibleAmountIncentiveGivenType(vehicleIncentives, "state")
    const highestUtilityIncentive = highestEligibleAmountIncentiveGivenType(vehicleIncentives, "Power Supplier")
    const federalStateUtilityIncentiveSavings = FormatAsDollars(incentiveSavingsOfGivenTypes(vehicleIncentives, ["Country", "state", "power supplier"]))
    const federalLeaseIncentive = vehicle.incentives.find(incentive => incentive.id === 681);
    const tooltip = useSelector(getTooltips)

    useEffect(() => {
       let amount = 0;
      
       if(activePurchaseMethod === "cash") {
              const leaseIncentiveID = 718;
              const hyundaiIncentive = vehicle.incentives.find(incentive => incentive.id === leaseIncentiveID);
              if(hyundaiIncentive) {
                     amount = hyundaiIncentive.evaluation.amount_in_purchase;
              }
       } else if(activePurchaseMethod === "lease") {
              const leaseIncentiveID = 681;
              const hyundaiIncentive = vehicle.incentives.find(incentive => incentive.id === leaseIncentiveID);

              if(hyundaiIncentive) {
                     amount = hyundaiIncentive.evaluation.amount_in_lease;
              }
       }
       setHyundaiIncentiveAmount(amount);
}, [activePurchaseMethod, vehicle.incentives]);

    const incentiveList = [
       {
              grantorType: "EV Lease Incentives",
              amount: hyundaiIncentiveAmount,
              link: federalLeaseIncentive?.details_url,
              description: hyundaiIncentiveAmount > 0 ? "Max potential lease incentive" : "No lease incentives available in your area",
              name: "Incentive Name"
          },
        {
            grantorType: "State",
            amount: highestStateIncentive?highestStateIncentive?.evaluation?.amount_in_purchase : 0,
            description: highestStateIncentive ? `Max potential incentives from ${state}` : `State incentives are not available at this time`,
            link: highestStateIncentive?.details_url,
            name: highestStateIncentive?.name
        },
        {
            grantorType: "Utility",
            amount: highestUtilityIncentive ?highestUtilityIncentive?.evaluation?.amount_in_purchase : 0,
            description: highestUtilityIncentive ? `Max potential incentives available in your area` : `Utility incentives are not available at this time`,
            link: highestUtilityIncentive?.details_url,
            name: highestUtilityIncentive?.name
        }
    ]

    const renderIncentives = incentiveList.map(incentive => {
        return (
            <IncentiveCard
                key={incentive.grantorType}
                grantor={incentive.grantorType}
                amount={FormatAsDollars(incentive.amount)}
                description={incentive.description}
                link={incentive.link}
            />
        )
    })

    const getVehicleImage = (vehicle) => {
      return vehicle.images[0].url_full;
    }

    const getSubheading = () => {
      if (!vehicle.model) {
        return `Update your ZIP code to see everything you may qualify for just by owning a ${vehicle.model_year} ${vehicle.model}.`;
      }

      if (vehicle.model.includes("SANTA")) {
        return "Update your ZIP code to see potential incentives you may qualify for just by owning a PHEV like the SANTA FE Plug-in Hybrid.";
      }

      if (vehicle.model.includes("KONA")) {
        return "Update your ZIP code to see potential incentives you may qualify for just by owning an EV like KONA Electric.";
      }

      if (vehicle.model.includes("TUCSON")) {
        return "Update your ZIP code to see potential incentives you may qualify for just by owning a PHEV like the TUCSON Plug-in Hybrid.";
      }

      if (vehicle.model.includes("IONIQ Plug-In Hybrid")) {
        return "Update your ZIP code to see potential incentives you may qualify for just by owning a PHEV like the IONIQ Plug-in Hybrid.";
      }

      if (vehicle.model.includes("IONIQ 5")) {
        return "Update your ZIP code to see potential incentives you may qualify for just by owning an EV like the IONIQ 5.";
      }

      if (vehicle.model.includes("IONIQ 6")) {
        return "Update your ZIP code to see potential incentives you may qualify for just by owning an EV like the IONIQ 6.";
      }
    };

    const getDisclaimer = () => {
      if (vehicle.model.includes("KONA") || vehicle.model.includes("IONIQ") || vehicle.model.includes("TUCSON") || vehicle.model.includes('SANTA')) {
        return "Highest trim with optional features shown.";
      } else {
        return "2022 model shown. 2023 model may vary.";
      }
    }

    const left = <>
      <TabTitle
        header={`${federalStateUtilityIncentiveSavings} in potential tax<br/> credits and incentives.`}
        subHeader={getSubheading()}
        tooltip={tooltip?.incentivesTip}
        tooltipPlacement="left"
      />
      <Grid container rowSpacing={2} style={{margin: '25px auto 0px'}} className="form-fields-grid">
        <Grid item xs={12}>
            <SelectVehicle />
            &nbsp;
            <SelectTrim />
            <div className="vehicle-image" style={{margin:'25px auto'}}>
              <img src={getVehicleImage(vehicle)} alt="Vehicle" style={{width:'100%'}} />
            </div>
            &nbsp;
            <p className="vehicle-disclaimer" style={{fontSize:"12px", color:"#666666", textAlign:"center"}}>{getDisclaimer()}</p>
        </Grid>
      </Grid>
    </>

    const right = <>
      <Grid container rowSpacing={2} style={{maxWidth:'300px', margin: '0 auto'}} className="form-fields-grid">
        <TabTitle 
          subHeader={getSubheading()}
        />
        <Grid item xs={12} style={{marginTop:'25px'}}>
            <TextFieldZipcode />
        </Grid>
        <Grid item xs={12}>
              <SelectPurchaseMethod />
       </Grid>
      </Grid >
    </>

    return (
        <TabStructure
            left={left}
            middle={renderIncentives}
            right={right}
        />
    )
}

export default Incentives
