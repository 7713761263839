export const defaultTooltips = {
    costOwnershipTip: "Potential savings over five years is based on a combination of (i) potential tax credits and incentives and (ii) potential fuel savings over five years. Potential tax credits, incentives and fuel savings are referenced for informational purposes only. Eligibility for tax credits and incentives will vary. Estimated potential fuel savings over five years based on IONIQ 5’s efficiency rating of 114 MPGe combined (SE/SEL/Limited RWD), consumer input regarding the number of miles driven annually, fuel prices, electricity prices, and the estimated combined MPG of a comparison gas-powered vehicle. Assumes home charging of IONIQ 5. EPA estimates are for comparison purposes only and actual mileage will vary. Actual savings, if any, will vary and depend on conditions such as external elements, driving and charging habits, fuel/electricity price fluctuations, vehicle condition, and lithium ion battery age. See Incentives tab for details regarding tax credits and incentives.",
    electricityPriceTip: "Estimate your average price for electricity per kilowatt-hour.",
    emissionsTip: "Carbon emissions estimates are referenced for informational purposes only. Actual carbon emissions will vary. Comparison of estimated driving-related emissions over five years is based on IONIQ 5’s efficiency rating of 114 MPGe combined (SE/SEL/Limited RWD), consumer input regarding the number of miles driven annually and the estimated combined MPG of a comparison gas-powered vehicle. Gasoline emissions are calculated at 19.6 lbs. of carbon dioxide per gallon of gas and electricity-related emissions are calculated based on the average carbon intensity of the consumer’s local grid. Assumes home charging of IONIQ 5. EPA estimates are for comparison purposes only and actual mileage will vary.",
    fuelPriceTip: "Estimate the average price you pay for a gallon of gas.",
    incentivesTip:"Depending upon the purchaser’s state of residence, additional state and/or utility incentives may be available. Tax credits and additional incentives are not within Hyundai’s control and are subject to change or expire without notice. This information does not constitute tax, financial or legal advice. Please consult your tax, financial or legal professional to determine eligibility, amount of credits and/or incentives available, if any, and further details. For additional information regarding federal, state and utility incentives please see: <a href='https://afdc.energy.gov/laws' target='_blank'>Alternative Fuels Data Center: Federal and State Laws and Incentives</a>",
    mileageTip: "Estimate the number of miles you drive each year.",
    mpgTip: "Estimate the combined MPG of the gas-only vehicle this EV will be replacing.",
    potentialFuelSavingsTip: "Estimated potential fuel savings over five years based on IONIQ 5’s efficiency rating of 114 MPGe combined (SE/SEL/Limited RWD), consumer input regarding the number of miles driven annually, fuel prices, electricity prices, and the estimated combined MPG of a comparison gas-powered vehicle. Assumes home charging of IONIQ 5. EPA estimates are for comparison purposes only and actual mileage will vary. Actual savings, if any, will vary and depend on conditions such as external elements, driving and charging habits, fuel/electricity price fluctuations, vehicle condition, and lithium ion battery age.",
    potentialIncentivesTip: "Depending on the purchaser's state of residence, certain state tax credits and state and/or utility incentives may be available. These potential tax credits and incentives are not within Hyundai's control, are subject to change or expire without notice, and are referenced for informational purposes only. Eligibility for tax credits depends on the purchaser's tax liability and other factors. Tax credits are potential future benefits and not applied at the time of purchase. This information does not constitute tax, financial or legal advice. Please consult with your tax, financial or legal professional to determine eligibility, amount of credits and/or incentives available, if any, and further details. For additional information regarding state and utility incentives see: <a href='https://afdc.energy.gov/laws' target='_blank'>https://afdc.energy.gov/laws</a>.",
    trimTip: "The vehicle trim may affect your maximum potential tax credits and/or incentives based on purchase price, fuel consumption or other factors. MSRP excludes freight charges, tax, title, and license fees. Freight charges and actual dealer prices may vary. Vehicles displayed may contain optional equipment at additional cost. Accessory items shown may vary according to model and illustration.",
    zipcodeTip: "Used to estimate potential incentives and fuel prices in your area.",
    handle: ''
}