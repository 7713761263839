import { useEffect, useState } from "react";
import {
  updateZipcode,
  updateTrims,
  updateActiveTrim,
  updateElectricityPrice,
  updateFuelPrice,
  updateDealerId,
} from "../redux/Slices/userSlice";
import { getZipcode, getActiveTrim, getDealerId } from "../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { VEHICLE_DATA } from "../data/vehicles/VehicleData";
import dealers from "../data/dealers/dealersData";
import {
  useGetVehicleQuery,
  useGetLocationQuery,
} from "../redux/services/unifiedAPIQuery";
import { FormatAsTwoDecimals } from "./Format";
import { updateVehicles } from "../redux/Slices/vehiclesSlice";

const queryString = require("query-string");

export default function useGetData() {
  const [data, setData] = useState({
    validDealerId: null,
    activeTrim: null,
    vehicleData: null,
    locationData: null,
  });
  const dispatch = useDispatch();
  const {
    dealerId: queryDealerId,
    zipcode: queryZipCode,
    showBranding,
    showTabName,
    showZipCode,
    showModelCard,
  } = queryString.parse(window.location.search);
  const [skip, setSkip] = useState(true);

  const [vehicles, setVehicles] = useState(VEHICLE_DATA);
  const activeTrim = useSelector(getActiveTrim);
  const zipcode = useSelector(getZipcode);
  const dealerId = useSelector(getDealerId);

  const validDealerId = dealerId || queryDealerId || 999999;
  const validZipcode = zipcode || queryZipCode || 92708;

  const {
    status: vehicleStatus,
    data: vehicleData,
    error: vehicleError,
    isLoading: vehicleLoading,
  } = useGetVehicleQuery(
    {
      handle: activeTrim,
      postcode: validZipcode,
      dealerId: validDealerId,
    },
    { skip }
  );

  const {
    status: locationStatus,
    data: locationData,
    error: locationError,
    isLoading: locationLoading,
  } = useGetLocationQuery({
    postcode: validZipcode,
    dealerId: validDealerId,
  });

  useEffect(() => {
    if (vehicleError || locationError) {
      dispatch(updateZipcode(90210));
    }
  }, [vehicleError, locationError, dispatch]);

  useEffect(() => {
    if (validDealerId) {
      dispatch(updateDealerId(validDealerId));
      window.dealerId = validDealerId;
      let result = dealers.find((dealer) => dealer.dealerId === validDealerId);
      result !== undefined
        ? (window.dealerName = result.dealerName)
        : (window.dealerName = "unknown");
    }
    if (validZipcode) {
      dispatch(updateZipcode(validZipcode));
    }
  }, [dispatch, setSkip, validDealerId, validZipcode]);

  useEffect(() => {
    if (vehicles) {
      let trims = vehicles[0].trims;
      dispatch(updateVehicles(vehicles));
      dispatch(updateTrims(trims));
      dispatch(updateActiveTrim(trims[0]));
      setSkip(false);
    }
  }, [vehicles, zipcode, setSkip, dispatch]);

  useEffect(() => {
    if (locationData) {
      const electricityPrice = FormatAsTwoDecimals(
        locationData.location.regional_fuel_cost[0].electricity / 100
      );
      const fuelPrice = FormatAsTwoDecimals(
        locationData.location.regional_fuel_cost[0].gasoline
      );
      dispatch(updateElectricityPrice(electricityPrice));
      dispatch(updateFuelPrice(fuelPrice));
    }
  }, [locationData, dispatch]);

  useEffect(() => {
    if (vehicleStatus !== "fulfilled" || locationStatus !== "fulfilled") return;
    setData({ validDealerId, activeTrim, vehicleData, locationData });
  }, [
    validDealerId,
    activeTrim,
    vehicleStatus,
    vehicleData,
    locationStatus,
    locationData,
  ]);

  return {
    isLoading: vehicleLoading || locationLoading,
    isError: Boolean(vehicleError) || Boolean(locationError),
    error: vehicleError || locationError,
    setVehicles,
    showBranding,
    showTabName,
    showZipCode,
    showModelCard,
    ...data,
  };
}
