import Grid from '@mui/material/Grid';

const TabStructure = ({ left, middle, right }) => {
    return (
        <Grid container spacing={8} className="grid-root">
            <Grid item md={4} xs={12} className="grid-left">
                {left}
            </Grid>
            <Grid item md={4} xs={12} className="grid-center">
                {middle}
            </Grid>
            <Grid item md={4} xs={12} className="grid-right">
                {right}
            </Grid>
        </Grid>
    )
}

export default TabStructure
