import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  zipcode: null,
  activeVehicle: {},
  activeTrim: {},
  activePurchaseMethod: "lease",
  annualMileage: 10000,
  equivalentGasMPG: 30,
  electricityPrice: 0.45,
  fuelPrice: 3.25,
  percentElectric: 50,
  numYears: 5,
  trims: [],
  dealerId: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateZipcode: (state, action) => {
      state.zipcode = action.payload;
    },
    updateActiveVehicle: (state, action) => {
      state.activeVehicle = action.payload;
    },
    updateActiveTrim: (state, action) => {
      state.activeTrim = action.payload;
    },
    updateActivePurchaseMethod: (state, action) => {
       state.activePurchaseMethod = action.payload
    },
    updateAnnualMileage: (state, action) => {
      state.annualMileage = action.payload;
    },
    updateEquivalentGasMPG: (state, action) => {
      state.equivalentGasMPG = action.payload;
    },
    updateElectricityPrice: (state, action) => {
      state.electricityPrice = action.payload;
    },
    updateFuelPrice: (state, action) => {
      state.fuelPrice = action.payload;
    },
    updatePercentElectric: (state, action) => {
      state.percentElectric = action.payload;
    },
    updateTrims: (state, action) => {
      state.trims = action.payload;
    },
    updateDealerId: (state, action) => {
      state.dealerId = action.payload;
    },
  },
});

export const {
  updateZipcode,
  updateActiveVehicle,
  updateActiveTrim,
  updateActivePurchaseMethod,
  updateAnnualMileage,
  updateEquivalentGasMPG,
  updateElectricityPrice,
  updateFuelPrice,
  updatePercentElectric,
  updateTrims,
  updateMaintenance,
  updateDealerId,
} = userSlice.actions;

export default userSlice.reducer;
