import InputLabel from '@mui/material/InputLabel';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomizedToolTip from "../CustomizedToolTip/CustomizedToolTip"

const Input = ({
    title,
    options,
    value,
    onChange,
    tooltip,
    tooltipPlacement,
}) => {
    return (
        <FormControl fullWidth className="select-root">
            <InputLabel style={{color: "#666666"}} sx={{ fontSize: "14px", fontWeight: 700, transform: "translate(0, -8px) scale(1)" }} variant="standard">
                {title}
                {tooltip && <CustomizedToolTip title={tooltip} placement={tooltipPlacement}/>}
            </InputLabel>
            <NativeSelect
                sx={{
                    position: 'relative',
                    color: '#002C5E',
                    border: '1px solid #e6e6e6',
                    borderRadius: 0,
                    fontSize: 16,
                    fontWeight: 400,
                    padding: '6px 26px 5px 12px',
                    width: "100%",
                    zIndex: "5"
                }}
                onChange={(e) => { onChange(e.target.value) }}
                value={value}
                IconComponent={() => (
                    <div className="select-icon"
                        style={{
                            position: 'absolute',
                            right: '10px',
                            top: '10px',
                            zIndex: '4'
                        }}
                    >
                        <KeyboardArrowDownIcon />
                    </div>
                )}
                disableUnderline={true}
            >
                {options.map(({ value, label }) => <option key={label} value={value}>{label}</option>)}
            </NativeSelect>
        </FormControl>
    )
}

export default Input

Input.propTypes = {
    title: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onChange: PropTypes.func
};
