import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const token =
  process.env.REACT_APP_UNIFIED_API_TOKEN || "9e4b20b0031742bdda41ef9a9f2573dd";

export const unifiedApi = createApi({
  keepUnusedDataFor: 3600,
  tagTypes: ["Vehicle", "Location"],
  baseQuery: fetchBaseQuery({
    reducerPath: "unifiedAPI",
    baseUrl:
      process.env.REACT_APP_ENVIRONMENT === "production"
        ? "https://api.production.zappyride.com"
        : "https://api.beta.zappyride.com",
    prepareHeaders: (headers) => {
      headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => {
    return {
      getVehicle: build.query({
        query: ({ handle, postcode, dealerId }) => {
          return `vehicles/${handle.handle}?postcode=${postcode}&dealerId=${dealerId}&dealerName=${window.dealerName}&include_used_vehicles`;
        },
        providesTags: (_, __, { handle, postcode }) => [
          { type: "Vehicle", handle: handle.handle, postcode },
        ],
      }),
      getLocation: build.query({
        query: ({ postcode, dealerId }) =>
          `location?postcode=${postcode}&dealerId=${dealerId}&dealerName=${window.dealerName}`,
        providesTags: (_, __, { postcode, dealerId }) => [
          { type: "Location", postcode, dealerId },
        ],
      }),
    };
  },
});

export const { useGetVehicleQuery, useGetLocationQuery } = unifiedApi;
