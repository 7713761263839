import React from "react";
import PropTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import CustomizedToolTip from "../../CustomizedToolTip/CustomizedToolTip";
import { FormatAsDollars } from "../../../utils/Format";
import { useSelector } from "react-redux";
import { getTooltips } from "../../../redux/selectors";

const DoughnutGraph = ({
  header,
  subHeader,
  graphData,
  labels = ["Incentives", "Fuel savings", "Maintenance"],
  backgroundColor = ["#002C5E", "#00AAD2"],
  vehicle,
  activeTrim,
}) => {
  const tooltip = useSelector(getTooltips)
  
  const data = {
    maintainAspectRatio: false,
    responsive: false,
    labels: labels,
    datasets: [
      {
        data: graphData,
        backgroundColor: backgroundColor,
      },
    ],
  };

  const options = {
    cutout: 52,
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label({ label, raw }) {
            return `${label} ${FormatAsDollars(raw)}`;
          },
        },
      },
      legend: {
        position: "bottom",
        labels: {
          boxHeight: 0,
          boxWidth: 0,
          color: "transparent",
          font: {
            family: "HyundaiSansHead-Bold",
            size: "13px",
          },
          filter: (legendItem, data) =>
            data.datasets[0].data[legendItem.index] !== 0,
        },
        onClick: function () {
          return false;
        },
      },
    },
  };


  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: "14px", fontWeight: 700, color: "#333" }}>
          {header}
        </Typography>
      </Grid>
      <Grid item pb={2} xs={12}>
        <Typography sx={{ fontSize: "16px", fontWeight: 700, color: "#333" }}>
          {subHeader}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Doughnut data={data} options={options} />
      </Grid>
      <Grid container justifyContent="center" item xs={12}  className="doughnutGraph_tooltip_container">
        <Grid
          flexDirection="row"
          container
          justifyContent="center"
          alignItems="center"
          item
          xs={6}
          className="doughnutGraph_tooltip"
        >
          <div
            style={{
              width: "16px",
              height: "16px",
              backgroundColor: backgroundColor[0],
              margin: "0 5px 0 0",
              position: "relative",
              top: "-1px"
            }}
            className="doughnutGraph_block"
          />
          <div>
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: 700,
                lineHeight: "1.2em",
                color: "#333333",
                fontFamily: "HyundaiSansHead-Bold",
                margin:"5px 0",
                whiteSpace: "nowrap"
              }}
            >
              {labels[0]}  <CustomizedToolTip title={tooltip?.potentialIncentivesTip} placement="left" />
            </Typography>
          </div>
        </Grid>

        <Grid
          flexDirection="row"
          container
          justifyContent="center"
          alignItems="center"
          item
          xs={6}
          className="doughnutGraph_tooltip"
        >
          <div
            style={{
              width: "16px",
              height: "16px",
              backgroundColor: backgroundColor[1],
              margin: "0 5px 0 0",
              position: "relative",
              top: "-1px"
            }}
            className="doughnutGraph_block block_2"
          />
          <div>
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: 700,
                lineHeight: "1.2em",
                color: "#333333",
                fontFamily: "HyundaiSansHead-Bold",
                margin:"5px 0",
                whiteSpace: "nowrap"
              }}
            >
              {labels[1]} <CustomizedToolTip title={tooltip?.potentialFuelSavingsTip} placement="left" />
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DoughnutGraph;

DoughnutGraph.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  graphData: PropTypes.array,
  labels: PropTypes.array,
  backgroundColors: PropTypes.array,
};
