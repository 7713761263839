export const getZipcode = (state) => state.user.zipcode
export const getActiveVehicle = (state) => state.user.activeVehicle
export const getTrims = (state) => state.user.trims
export const getActiveTrim = (state) => state.user.activeTrim
export const getActivePurchaseMethod = (state) => state.user.activePurchaseMethod
export const getAnnualMiles = (state) => state.user.annualMileage
export const getEquivalentGasMPG = (state) => state.user.equivalentGasMPG
export const getElectricityPrice = (state) => state.user.electricityPrice
export const getFuelPrice = (state) => state.user.fuelPrice
export const getPercentElectric = (state) => state.user.percentElectric
export const getDealerId = (state) => state.user.dealerId
export const getTooltips = (state) => state.tooltips.tooltips
export const getVehicles = (state) => state.vehicles.vehicles