import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from "@mui/material/Link";
import GaTracker from "../../utils/GaTracker";

const IncentiveCard = ({ name, grantor, amount, description, link, dealerId}) => {
      const handleClick = () => {
    GaTracker.trackEvent({
      category: "Incentives",
      action: "Clicked Incentive Card",
      label: `${name} from ${grantor}`,
    });
  };

    return (
        <Card
            sx={{
                minWidth: 275,
                backgroundColor: "#F3F3F3",
                borderTop: "8px solid #002C5F",
                marginBottom: "24px",
                borderRadius: 0,
                boxShadow: "none"
            }}
        >
            <CardContent style={{padding:"16px 48px"}}>
                <Typography sx={{ fontSize: 16, fontWeight: 700 }} color="text.secondary" gutterBottom>
                    {grantor}
                </Typography>
                <Typography sx={{ fontFamily: "HyundaiSansHead", fontSize: 30, fontWeight: 700, mt: 4, mb: 0, color: "#333333" }} color="text.secondary">
                    {amount && amount.replace("$", '') === '0' ? `${amount}` : `Up to ${amount}`}
                </Typography>
                <Typography sx={{ fontSize: 16, fontWeight: 700, mb:5 }} variant="body2">
                    {description}
                </Typography>
                        {parseFloat(amount.replace(/[^0-9.-]+/g, "")) > 0 &&
          !description.toLowerCase().includes("not applicable") &&
          link && (
            <Link
              onClick={handleClick}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
              className="link-icon"
              style={{
                color: "#002C5F",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              Learn more
            </Link>
          )}
            </CardContent>
        </Card>
    )
}

export default IncentiveCard
