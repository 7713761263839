import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import FormControl from '@mui/material/FormControl';
import { InputAdornment, OutlinedInput } from '@mui/material';
import CustomizedToolTip from "../CustomizedToolTip/CustomizedToolTip"

const InputSlider = ({
    title,
    value,
    onChange,
    min,
    max,
    step = 10,
    startAdornment,
    endAdornment,
    maxLength,
    tooltip,
    tooltipPlacement,
    onBlur
}) => {
    return (
        <Box>
            <Typography style={{color: "#666666"}} sx={{ textAlign: "left", fontWeight: 700, fontSize: "14px" }} id="input-slider" gutterBottom>
                {title}
                {tooltip && <CustomizedToolTip title={tooltip} placement={tooltipPlacement} />}
            </Typography>
            <Grid container spacing={4} alignItems="center">
                <Grid item style={{ paddingTop: "20px" }} xs={8}>
                    <Slider
                        sx={{
                            color: "#00AAD2",
                            '& .MuiSlider-rail': {
                                color: '#888888',
                            },
                        }}
                        value={Number(value)}
                        onChange={(e) => { onChange(e.target.value) }}
                        aria-labelledby="input-slider"
                        step={step}
                        min={min}
                        max={max}
                        size="small"
                    />
                </Grid>
                <Grid item style={{ paddingTop: "20px", marginBottom: "0px" }} xs={4}>
                    <FormControl variant="outlined">
                        <OutlinedInput
                            className="ARE YOU HERE"
                            id="outlined-adornment-weight"
                            value={value}
                            onChange={(e) => { onChange(e.target.value) }}
                            onBlur={(e) => onBlur(e.target.value)}
                            startAdornment={<InputAdornment position="start">{startAdornment}</InputAdornment>}
                            endAdornment={<InputAdornment position="end" className="endAdornment">{endAdornment}</InputAdornment>}
                            aria-describedby={`outlined-${title}-helper-text`}
                            inputProps={{
                                'aria-label': title,
                                maxLength: maxLength
                            }}
                            sx={{
                                color: "#002C5E",
                                fontSize: "16px",
                                fontWeight: 400,
                                '& .MuiInputBase-input': {
                                    padding: "4px 0px",
                                    left: "-5px",
                                    position: "relative"
                                },
                                paddingLeft: "4px",
                                paddingRight: "0px",
                                '& .Mui-focused': {
                                    fontSize: "40px"
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderRadius: "0"
                                }
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
}

export default InputSlider;

InputSlider.propTypes = {
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
};